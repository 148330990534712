
<template>
  <div class="justify-content-center no-gutter">
    <div class="container-xxl">
      <img src="images/DatuPuti_Welcome_H600.png" class="img-fluid mx-auto d-block" alt="Datu Puti Welcomes You"
        height="200">

      <NavBar />
    </div>

    <router-view />

    <Footer />

  </div>
</template>

<script>

import NavBar from './components/NavBar.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',

  components: {
    NavBar,
    Footer
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
