<template>
    <nav class="navbar fixed-bottom bg-body-tertiary">
        <div>
            <router-link :to="{ name: 'Home' }" class="nav-link">
                <img src="DatuPuti.ico" width="42" height="36" class="d-inline-block align-text-center" alt="Datu Puti">
                Copyright &copy; 2023 by Thomas Borland. All rights reserved.
            </router-link>
        </div>
    </nav>
</template>

