<template>
    <nav class="navbar navbar-expand-sm" style="background-color: #e40000">
        <a class="navbar-brand ms-3" href="#">Datu Puti</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <router-link :to="{ name: 'Home' }" class="nav-link">Home</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{ name: 'Socials' }" class="nav-link">Socials</router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{ name: 'MCADay' }" class="nav-link">Marvel</router-link>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://streamelements.com/mr_datu_puti/tip" target="_blank">Donate</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="https://merch.streamelements.com/mr_datu_puti" target="_blank">Shop</a>
                </li>
            </ul>
        </div>
    </nav>
</template>