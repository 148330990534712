<template>
   <div class="container py-2">
      <div class="container my-3 d-flex align-items-center justify-content-center">
         <h2 class="display-5">Here there and every where!
            <br /><small>Where would you like to enjoy the full Datu Puti experience?</small>
         </h2>
      </div>

      <div class="container m-5 d-grid gap-4 col-6 mx-auto">
         <a class="btn btn-danger rounded-pill" type="button" href="https://www.twitch.tv/mr_datu_puti" target="_blank"><i
               class="bi bi-twitch">&nbsp;Twitch</i></a>

         <a class="btn btn-danger rounded-pill" type="button" href="https://www.youtube.com/user/datuputi1"
            target="_blank"><i class="bi bi-youtube">&nbsp;YouTube</i></a>

         <a class="btn btn-danger rounded-pill" type="button"
            href="https://www.facebook.com/Datu-Puti-Gaming-105435718980234" target="_blank"><i
               class="bi bi-facebook">&nbsp;Facebook</i></a>

         <a class="btn btn-danger rounded-pill" type="button" href="https://trovo.live/s/Datu_Puti"
            target="_blank">Trovo</a>

         <a class="btn btn-danger rounded-pill" type="button" href="https://kick.com/datu-puti" target="_blank">Kick</a>

         <a class="btn btn-danger rounded-pill" type="button" href="https://streamelements.com/mr_datu_puti"
            target="_blank">StreamElements</a>

         <a class="btn btn-danger rounded-pill" type="button" href="https://twitter.com/datu_puti" target="_blank"><i
               class="bi bi-twitter">&nbsp;Twitter</i></a>

         <a class="btn btn-danger rounded-pill" type="button"
            href="https://discord.com/channels/1017641877991862312/1017641877991862314" target="_blank"><i
            class="bi bi-discord">&nbsp;Discord</i></a>

      <a class="btn btn-danger rounded-pill" type="button" href="https://fortnitetracker.com/profile/all/Mr_Datu_Puti"
         target="_blank">Fortnite Tracker stats</a>
   </div>
</div></template>