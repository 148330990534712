<template>
    <div class="container-xxl justify-content-center m-3" v-if="obj.statusCode == 200">

        <div class="container">
        <div class="row">
            <div class="col-sm2">
                <img class="img-thumbnail" :src="obj.imgSrc" />
            </div>
            <div class="col-sm2">
                <h3>{{ obj.imgTitle }}</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-sm2">
                <a href="http://marvel.com" target="_blank" class="link-dark" >{{ obj.copyright }}</a>
            </div>
        </div>
        </div>

        <div class="accordion accordian-flush justify-content-center" id="accordionExample">

            <div class="accordion-item" v-if="obj.comics.items.length > 0" >
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        :data-bs-target="`#collapseOne`" aria-expanded="true" :aria-controls="`collapseOne`"> Comics
                    </button>
                </h2>
                <div :id="`collapseOne`" class="accordion-collapse collapse" aria-labelledby="headingOne">
                    <div class="accordion-body">
                        <ul class="no-bullets" v-if="obj.comics.items.length > 0">
                            <li v-for="(comic, index) in obj.comics.items" :key="index">
                                {{ comic.name }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="accordion-item" v-if="obj.series.items.length > 0">
                <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        :data-bs-target="`#collapseTwo`" aria-expanded="true" :aria-controls="`collapseTwo`"> Series
                    </button>
                </h2>
                <div :id="`collapseTwo`" class="accordion-collapse collapse" aria-labelledby="headingTwo">
                    <div class="accordion-body">
                        <ul class="no-bullets" v-if="obj.series.items.length > 0">
                            <li v-for="(series, index) in obj.series.items" :key="index">
                                {{ series.name }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="accordion-item" v-if="obj.stories.items.length > 0">
                <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        :data-bs-target="`#collapseThree`" aria-expanded="true" :aria-controls="`collapseThree`">
                        Stories
                    </button>
                </h2>
                <div :id="`collapseThree`" class="accordion-collapse collapse" aria-labelledby="headingOne">
                    <div class="accordion-body">
                        <ul class="no-bullets" v-if="obj.stories.items.length > 0">
                            <li v-for="(story, index) in obj.stories.items" :key="index">
                                {{ story.name }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="accordion-item" v-if="obj.events.items.length > 0">
                <h2 class="accordion-header" id="headingFour">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        :data-bs-target="`#collapseFour`" aria-expanded="true" :aria-controls="`collapseFour`"> Events
                    </button>
                </h2>
                <div :id="`collapseFour`" class="accordion-collapse collapse" aria-labelledby="headingOne">
                    <div class="accordion-body">
                        <ul class="no-bullets" v-if="obj.events.items.length > 0">
                            <li v-for="(event, index) in obj.events.items" :key="index">
                                {{ event.name }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import axios from 'axios';

export default {
    setup() {
        // data
        const obj = ref({
            imgSrc: '',
            imgTitle: '',
            copyright: '',
            comics: [],
            marvelChar: [],
            comics: [],
            series: [],
            stories: [],
            events: [],
            showComics: '',
            showSeries: '',
            showStories: '',
            showEvents: false,
            statusCode: 0
        })

        // methods
        const fetchMarvel = () => {
            var imgSize = "/portrait_xlarge";
            var url = 'http://192.168.68.134:3000/marvel';

            axios.get(url).then(function (res) {
                obj.value.statusCode = res.data.code;
                obj.value.copyright = res.data.attributionText;
                obj.value.marvelChar = res.data.data.results;

                obj.value.imgTitle = obj.value.marvelChar[0].name;
                obj.value.imgSrc = obj.value.marvelChar[0].thumbnail.path + imgSize + "." + obj.value.marvelChar[0].thumbnail.extension;

                obj.value.comics = obj.value.marvelChar[0].comics;
                obj.value.series = obj.value.marvelChar[0].series;
                obj.value.stories = obj.value.marvelChar[0].stories;
                obj.value.events = obj.value.marvelChar[0].events;

                console.log(obj.value.comics);
            })
        }
        // computed
        onMounted(() => {
            fetchMarvel();
        })

        // lifecycle hooks


        return { obj, fetchMarvel }
    }
}
</script>

<style>
.accordion {
  --bs-accordion-active-bg: #e40000
}
.accordion-button.collapsed {
    background: #e40000
}
.accordion-body {
    background-color: rgba(237, 149, 149, 0.9)
}
ul.no-bullets {
  list-style-type: none;
}
</style>