import { createRouter, createWebHistory } from "vue-router"
import Home from '../views/Home.vue'
import Socials from '../views/Socials.vue'
import Mcaday from "../views/Mcaday.vue"

const routes = [
    {
        path: "/",
        name: 'Home',
        component: Home
    },
    {
        path: "/socials",
        name: 'Socials',
        component: Socials
    },
    {
        path: "/mcaday",
        name: 'MCADay',
        component: Mcaday
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes
})

export default router;